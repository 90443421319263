




import Vue from 'vue';
import lottieWeb from 'lottie-web';

export default Vue.extend({
   name: 'lottie',
   props: {
      height: {
         default: ''
      },
      width: {
         default: ''
      },
      scale: {
          default: 0
      },
      maxHeight: {
         default: ''
      },
      maxWidth: {
         default: ''
      },
      renderer: {
         default: 'svg'
      },
      animation: {
         required: true,
         type: String
      },
      autoplay: {
         type: Boolean,
         default: true
      },
      loop: {
         type: Boolean,
         default: true
      },
      speed: {
         type: Number,
         default: 1
      }
   },
   computed: {
      style(): object {
         const style = { width: '', height: '' };
         if (this.height !== '') {
            style.height = this.height;
         }
         if (this.width !== '') {
            style.width = this.width;
         }
         if (this.maxWidth !== '') {
            style['max-width'] = this.maxWidth;
         }
         if (this.maxHeight !== '') {
            style['max-height'] = this.maxHeight;
         }
         if (this.scale !== 0) {
            style['transform'] = `scale(${this.$props.scale})`;
         }
         return style;
      }
   },
   data() {
      return {
         anim: undefined as any
      };
   },
   watch: {
      path: {
         handler() {
            this.init();
         }
      }
   },
   mounted() {
      this.init();
   },
   methods: {
      init() {
         this.anim = lottieWeb.loadAnimation({
            container: this.$refs.bm as any,
            renderer: 'svg',
            loop: this.loop,
            autoplay: this.autoplay,
            path: '/animations/' + this.animation + '.json'
         });

         this.anim.setSpeed(this.speed);

         this.$emit('AnimControl', this.anim);
      }
   }
});
